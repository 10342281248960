<template>
  <v-app>
      <Oso/>
  </v-app>
</template>

<script>
// @ is an alias to /src
import Oso from '@/components/Oso.vue'

export default {
  name: 'OsoView',
  components: {
    Oso
  }
}
</script>

